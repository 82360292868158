
import {computed, defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import store from "../../store";
import {LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import RecentCards from "../../components/base/common/RecentCards.vue";
import Entities from "../../components/base/common/Entities.vue";
import ClaimCard from "@/views/claim/ClaimCard.vue";

export default defineComponent({
  name: "DeletedClaim",
  components: {ClaimCard, Entities, RecentCards},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Deleted Claims',
        [
          {link: false, router: '', text: 'Deleted Claims'}
        ]
      )
    })
    const filterObject = ref({deleted: true})
    const page = computed(() => store.state.ClaimModule.listPageClaim)
    return {
      page,
      ...LoadFilterObjects(Actions.LOAD_LIST_CLAIMS, filterObject.value, ['client', 'insured', 'caze', 'examiner'])
    }
  }
})
